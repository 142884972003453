@if (type === 'duotone') {
  <span [ngClass]="['ki-'+type, 'ki-'+name, class, color ? 'text-' + color : '', size ? 'font-' + size : '']"
        [style.color]="color">
  @for (i of [].constructor(pathsNumber); track i; let idx = $index) {
    <span class="path{{ idx + 1 }}"></span>
  }
  </span>
} @else {
  <span [ngClass]="['ki-'+type, 'ki-'+name, class, color ? 'text-' + color : '', size ? 'font-' + size : '']"
        [style.color]="color"></span>
}
